import React from "react"
import PropTypes from "prop-types"
import LinearGradient from "../ui/LinearGradient"
import pipesImage from "../../images/pipes.svg"

const ThermalBackground = ({ children, style: customStyle, ...otherProps }) => {
  return (
    <LinearGradient
      component="div"
      angle={-70}
      stops={[
        ["9%", "#FF8F0F"],
        ["67%", "#DA2A2A"],
        ["100%", "#001362"],
      ]}
      style={{ ...customStyle, position: "relative", overflow: "hidden" }}
      {...otherProps}
    >
      <div style={{ position: "relative", zIndex: 2 }}>{children}</div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          backgroundImage: `url('${pipesImage}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0 24px",
        }}
      />
    </LinearGradient>
  )
}

ThermalBackground.defaultProps = {
  children: null,
  style: {},
}
ThermalBackground.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
}
export default ThermalBackground
