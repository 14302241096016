import React from "react"
import PropTypes from "prop-types"

const Svg = ({ fill }) => {
  return (
    <svg width="100%" length="auto" viewBox="0 0 1440 89">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M0,0 C369.333333,52 611.666667,78 727,78 C842.333333,78 1080,52 1440,0 L1440,89 L0,89 L0,0 Z"
          id="concave-bottom"
          fill={fill}
        ></path>
      </g>
    </svg>
  )
}

Svg.propTypes = {
  fill: PropTypes.string,
}
export default Svg
