import React from "react"
import PropTypes from "prop-types"
import { getColor } from "../Theme/selectors"
import withTheme from "../Theme/withTheme"

const isNum = num => /^\d+$/.test(num) || /^\d+\.\d+$/.test(num)

/**
 * Returns a composed linear gradient css attribute from given angle and
 * colour stops.
 *
 * @param {number} The angle of the gradient.
 * @param {array} An array of gradient colour stops `[[percent, colour], [percent, colour], ...]`
 *
 * @returns {string}
 */
const cssLinearGradient = (angle, stops, uiTheme) => {
  const sortedStops = stops
    .slice()
    .map(stop => {
      const [location, color] = stop
      return `${getColor(uiTheme, color)} ${
        isNum(location) ? `${location}px` : location
      }`
    })
    .join(",")

  return `linear-gradient(${angle}deg, ${sortedStops})`
}

const LinearGradient = props => {
  const { angle, stops, style: customStyle, uiTheme, ...otherProps } = props

  const style = {
    background: cssLinearGradient(angle, stops, uiTheme),
    ...customStyle,
  }

  return <div {...otherProps} style={style} />
}

LinearGradient.propTypes = {
  angle: PropTypes.number,
  stops: PropTypes.array.isRequired,
  style: PropTypes.object,
  uiTheme: PropTypes.object,
}

LinearGradient.defaultProps = {
  angle: 0,
  style: {},
  uiTheme: null,
}

export default withTheme(LinearGradient)
